import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import  './router/router.js'
import  './assets/style/index.scss'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
const app = createApp(App)
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
app.use(ElementPlus, {locale: zhCn})
// 注册vuex
import store from './store/index.js'
app.use(store)

app.use(ElementPlus)
app.use(store).use(router).mount('#app')

// 自定义权限按钮 v-permission
const _has = (val: any) => {

  const isShow = false
  let permissionStr = ""
  let { user } = store.state as any
  if (user.menuCodeBtn === undefined || user.menuCodeBtn == null) {
    return isShow
  } else {
    permissionStr = user.menuCodeBtn.toString();
  }
  if (permissionStr.indexOf(val) > -1) {
    return true
  }
  return isShow
}

app.directive('permission', {
  mounted(el, binding) {
    if (!_has(binding.value))
		/* if (!store.state.role[binding.value]) */ {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
})