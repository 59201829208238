
import request from '@/utils/request'

// 登出
export function logout(data) {
  return request({
    url: '/auth/ser/logout',
    method: 'post',
    transformRequest: [data => {
      let formData = new FormData()
      for (let key in data) {
        formData.append(key, data[key])
      }
      return formData
    }],
    data: data
  })
}

//获取用户信息
export function gettUser(data) {
  return request({
    url: '/auth/ser/token',
    method: 'post',
    transformRequest: [data => {
      let formData = new FormData()
      for (let key in data) {
        formData.append(key, data[key])
      }
      return formData
    }],
    data: data
  })
}

// 校验登录是否失效
export function tokenValidity(data) {
  return request({
    url: '/auth/ser/login/tokenValidity',
    method: 'post',
    transformRequest: [data => {
      let formData = new FormData()
      for (let key in data) {
        formData.append(key, data[key])
      }
      return formData
    }],
    data: data
  })
}