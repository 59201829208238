import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import login from '../views/LoginView.vue'
import Layout from '@/components/menu/indexView.vue'
 export const constantRouterMap: Array<RouteRecordRaw> = [

  {
    path: '/login',
    name: 'login',
    component:(resolve) => require([`@/views/LoginView`], resolve)
  },
  {
    path: '/loadingPage',
    name: 'loadingPage',
    component: () => import('../views/loadingPage.vue')
  },
  {
    path: "/404",
    component: () => import("../views/features/404.vue"),
  },
  {
    path: '/',
    redirect: '/dataAnalyse/businessView/businessViewList'
  },
  {
		path:'/:catchAll(.*)',
	  component: () => import("../views/features/404.vue")
	}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes:constantRouterMap
})

export default router
