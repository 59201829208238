import router from "./index";
import store from '../store/index.js'
import { getToken, setToken,removeToken } from "@/utils/auth"; // getToken from cookie
import { judegLoginUrl } from "@/utils/commonfun"; // getToken from cookie
import {ElMessageBox,} from 'element-plus'
const { gettUser } = require('@/api/userLogin.js')
import Layout from '@/components/menu/indexView.vue'
const whiteList = ["/login",]; // no redirect whitelist
let pageRefresh = true //防止页面刷新 路有清空
router.beforeEach((to, from, next) => {
  // 获取token
  if (to.query?.code) {
    setToken(to.query.code)
  }
  document.querySelector('meta[name="description"]').setAttribute('content', to.name+"这是一个描述");

  //  判断页面有无token
  if (!getToken() && whiteList.indexOf(to.path) == -1) {
    judegLoginUrl()
  } else {
    if (!pageRefresh) {
      setinfo(to).then(() => {
        next()
      })
    } else {
      getMneuAuserInfo().then(res => {
        setinfo(to).then(() => {
          let path = to.path
          if(to.query.redirectUrl&&to.query.redirectUrl.indexOf("hiddencustomReportList")!=-1){
            next({ path: to.query.redirectUrl,query:{
              
              reportClassifyId:to.query.redirectUrl.split("reportClassifyId=")[1],
              reportId:to.query.reportId
            } })

          }else if (path.indexOf('loadingPage') != -1) {
            next({ path:getUrl(res[0].children[0].children)   })
          } else {
            next({ ...to, replace: true }) //解决刷新页面一步加载问题
          }
        })
      })
      pageRefresh = false
    }
  }
})
//获取第一个显示菜单地址
const getUrl = (arr)=>{
  let url = '' ,status = true;
  arr.forEach((el) => {
    let componenturl = el.componenturl+''
    if(status&&componenturl.indexOf('hidden')==-1&&el.type=='0'){
        url = JSON.parse(JSON.stringify(componenturl))
        status = false
      }
  });
  return url
}
// 设置信息
const setinfo = (to) => {
  setrouter(to.path)
  store.commit('setdefaultActive', to.path)//左侧菜单默认选中
  document.title = to.name ? to.name + '-数据中台' : '数据中台'

  return new Promise((resolve, reject) => {
    resolve()
  })
}
// 获取当前路由
function setrouter(path) {
  let pageurl = '/' + path.split("/")[1]
  let topMenuList = store.state.user.topMenuList
  let indexNum = topMenuList.findIndex((v) => v.path == pageurl)
  let obj = store.state.user.topMenuList[Number(indexNum)]

  if (!obj?.path) return
  getleftMENU(obj.children, obj.path)
}

// 获取侧边菜单
function getleftMENU(arr, pageUrl) {
  store.commit('getleftMenu', arr) //获取当前系统的一级菜单
  store.commit('settopActive', pageUrl)
}

// 获取路由地址
const getRouter = ((arr, url) => {
  arr.forEach((element) => {
   
    element['path'] = element['pageUrl']
    let str = element['pageUrl'].indexOf('/') == -1 ? '/' : ''
    element['componenturl'] = url + str + element['pageUrl']
    delete element['pageUrl']
    element['mate'] = {
      title: element['name'],
      icon: element['icon'],
    }
    if (!element.compos.length) {
      const str = element['componenturl']
      element['component'] = loadView(str)
    }

  })
  return arr.filter((router) => {
    if (router.compos && router.compos.length) {
      
      router['children'] = getRouter(router.compos, router['componenturl'])
      delete router.compos
    }
    return true
  })
})
export const loadView = (view) => {
  return () => require.ensure([], (require) => require(`@/views${view}`))
}
// 获取菜单与用户信息
function getMneuAuserInfo() {
  return new Promise((resolve, reject) => {
    gettUser().then((res) => {
      if (res.code == 200) {
        if(!res.data.srp.compos.length){
          ElMessageBox.alert('暂无权限访问，您可以联系管理员添加页面权限',
          '系统提示', {
            confirmButtonText: '退出',
            type: 'warning',
            showClose: false,
            closeOnClickModal: false,
            showCancelButton: false,
            callback: action => {
              removeToken()
              judegLoginUrl()
            }
          })
          return false
        }
        store.commit("setUserInfo", res.data.srp.user)
        store.commit("getMenuBtn", res.data.srp.menuCodes)
        res.data.srp.children && res.data.srp.children[2].children.forEach((element) => {
          element['path'] = element['pageUrl']
          element['mate'] = {
            title: element['name'],
            icon: element['icon'],
          }
        })
        let compos = res.data.srp.compos
        let arr = compos.filter((item) => { return item.id == '45' })
        let routerList = getRouter(arr[0].compos, '')
    
        routerList.forEach(element => {
          element['component'] = Layout
          let newrouterList = []
          if(element.children&&element.children.length==7){
            // delete element.children[6]
            element.children.forEach(el => {
             
              if(el.name!='报表'){
                newrouterList.push(el)
              }
            });
            element.children = newrouterList
          }
          
          router.addRoute(element)
        });

        store.commit('gettopMenuList', arr[0].compos) //获取当前系统的一级菜单
        resolve(routerList)
      }
    })

  })
}





export default router