import request from '@/utils/request'
import qs from 'qs'
// 字典
export function dictionaryList(data) {
  return request({
    url: "/auth/setting/dictionary/list",
    method: "post",
    transformRequest: [data => {
      let formData = new FormData()
      for (let key in data) {
        formData.append(key, data[key])
      }
      return formData
    }],
    data
  });
}

export function downloadListInfo(url, params) {
  return request({
    url:url+ '?' + qs.stringify(params, {
      indices: false
    }),
    method: 'get',
    responseType: 'blob'
  })
}

export function downloadListInfoPost(url, data) {
  return request({
    url,
    method: 'post',
    data,
    transformRequest: [data => {
      let formData = new FormData()
      for (let key in data) {
        formData.append(key, data[key])
      }
      return formData
    }],
    responseType: 'blob'
  })
}

// 查询公司所有人员
export function allList(params) {
  return request({
    url: '/report/employee/allList' + '?' + qs.stringify(params, {
      indices: false
    }),
    method: 'get',
  })
}
// 战队战区
export function getProductType(data) {
  return request({
    url: "/analyse/sale/getProductType",
    method: "post",
    transformRequest: [data => {
      let formData = new FormData()
      for (let key in data) {
        formData.append(key, data[key])
      }
      return formData
    }],
    data
  });
}
