import store from '@/store/index.js'
const { downloadListInfo,downloadListInfoPost} = require('@/api/common')
/* number格式*/
export function handelnum(num, callback) {
  if (!num) return (num || 0)
  num += ''
  return num.split("").reverse().reduce((prev, next, index) => {
    return ((index % 3) ? next : (next + ',')) + prev;
  })

}

// 获取时间
export function timeType(timestamp = new Date().getTime(), num) {
  var date = new Date(timestamp)
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  if (num == 3) {
    return Y + M + D
  } else {
    return Y + M + D + ' ' + h + m + s
  }
}

// 正则 元 万 千万 千分位保存
export function handelNumType(num, type = '元', isQ = true) {
  let newnum = '',
    lastnum = '',
    returnnum
  num = type == '元' ? num : type == '万元' ? num / 10000 : num / 10000000
  num = parseFloat(num)
  num = num.toFixed(2)
  num = num + ''
  if (isQ) {
    newnum = num.split('.')[0]
    lastnum = num.split('.')[1]
    newnum = newnum.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  }
  returnnum = isQ ? (newnum + '.' + lastnum) : num
  return returnnum
}
// 判断当前跳转登录地址
export function judegLoginUrl() {
  let url = window.location.href
  url =url.indexOf("com") != -1? url.split("com")[1]:url.split("9403")[1]
   let urlStr = '&redirect='+url
  window.location.href =  (window.location.href.indexOf("datan.ysbuy.com/") != -1)? 'https://data.ysbuy.com/login?redirect=%2Fdashboard'+urlStr : window.location.href.indexOf("datant.ysbuy.com/") != -1 ?'https://datat.ysbuy.com/login?redirect=%2Fdashboard'+urlStr:'http://192.168.3.61:9402/login?str=logout'+urlStr
}

// 下载文件
export function downloadFile(obj, name, suffix) {
  const url = window.URL.createObjectURL(new Blob([obj]))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  const fileName = timeType(new Date(), 6) + '-' + name + '.' + suffix
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function downfile (url,data,fileName,suffix='xlsx',method='get'){
  return new Promise((solve,reject)=>{
    let api = method=='get'?downloadListInfo:downloadListInfoPost
    api(url,data).then(res=>{
      downloadFile(res,fileName,suffix)
      solve(res)
    }).catch((error)=>{
      reject(error)
    })
  })
}

export function getHeight (height=200){
  let newHeight = 0
  window.onresize = () => {
    return (() => {
      let clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      let searchheight = 0
      if (document.getElementById('searchBox')?.offsetHeight) {
        searchheight = document.getElementById('searchBox')?.offsetHeight || 0
      }
     newHeight = clientHeight - searchheight - height
     store.commit("setHeight", newHeight)
    })()
  }
  let boxheight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  let sheight = document.getElementById('searchBox')?.offsetHeight || 0
 newHeight = boxheight - sheight - height
store.commit("setHeight", newHeight+20)
return newHeight

}