import axios from 'axios'
import {
  ElMessage,
  ElMessageBox,
  ElNotification
} from 'element-plus'
import {
  getToken,
  removeToken
} from '@/utils/user'
import Config from '@/utils/settings'
import {
  useRoute,
  useRouter
} from 'vue-router'
import {
  judegLoginUrl
} from "@/utils/commonfun"; // getToken from cookie
const router = useRouter()
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_ENV_URL, // api 的 base_url
  timeout: Config.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    let url = config.url

    if (getToken()) {
      config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json'
    config.baseURL = process.env.VUE_APP_BASE_API1
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    let code = response.code || response.data.code || response.status || response.data.status
    let message = response.data.info || response.data.messages || '接口请求失败'
    message = message == 'PERMISSION_DENIED' ? '请输入正确账号和密码' : code == 404 ? "未找到接口" : message
    if (code == -1) {
      ElMessageBox.alert('暂无权限访问，您可以联系管理员添加权限',
        '系统提示', {
          confirmButtonText: '退出',
          type: 'warning',
          showClose: false,
          closeOnClickModal: true,
          showCancelButton: false,
          callback: action => {
            removeToken()
            judegLoginUrl()
          }
        })
    } else if (code == -20) {
      ElMessageBox.alert('登录已过期',
        '系统提示', {
          confirmButtonText: '退出',
          type: 'warning',
          showClose: false,
          closeOnClickModal: false,
          showCancelButton: false,
          callback: action => {
            removeToken()
            judegLoginUrl()
          }
        })
    } else if (code && code != 200) {
      ElMessage.error(message)
    } else {
      return response.data
    }
  },
  error => {
    let status = error.response?.status
    let message = status == 404 ? '未查询到接口' : '接口请求失败'
    ElNotification.error(message)
    return Promise.reject(error)
  }
)
export default service